<template>
  <teleport to="body">
    <div class="invoice-red-package">
      <div class="toast"></div>
      <div class="red-package-content">
        <img class="close_lotter" src="https://shijiaoke.oss-cn-beijing.aliyuncs.com/News/faa59b1650dbacb64250045ea371df39.png" alt="" @click="close">
          <h1 class="lottery-text">
              <span style="position: relative;top: 1rem;">{{hint}}<span id="Message">{{awardName}}</span></span>
              <img id="try-winnerIMG" :src="src" alt="获奖图片" />
          </h1>
          <a href="javaScript:void(0);"><img src="@/assets/img/lottery/bg21.png" style="width: 5.5rem" @click="close"></a>
      </div>
    </div>
  </teleport>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { useRouter } from "vue-router";
export default defineComponent({
  name: "RedPackage",
  emits:['close'],
  props: {
    src: {
      type: String,
      default: "",
    },
    awardName: {
      type: String,
      default: ""
    },
    hint: {
      type: String,
      default: ""
    },

    target: {
      type: String,
      default: "",
    },
    params: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  setup(props, ctx) {
    const router = useRouter();
    function onOpenPackage() {
      ctx.emit('close')
    }
    function close() {
       ctx.emit('close')
    }
    return {
      onOpenPackage,
      close
    };
  },
});
</script>


<style lang="scss" scoped>
.invoice-red-package {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 100;
  width: 100%;
  height: 100%;
  .toast {
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.75);
  }
  .red-package-content {
    text-align: center;
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    transform: translateY(-50%);
    img{
      width: 100%;
    }
    .close{
      width: 32px;
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(400%);
      z-index: 100;
    }
  }
}


.lottery-text {
    position: absolute;
    left: 11%;
    top: 49.5%;
    width: 80%;
    /* margin-left: -40%; */
    z-index: 20;
    color: #ec3f3f;
    font-size: 0.20rem;
    text-align: center;
}

#try-winnerIMG {
  width: 1.5rem;
  height: 1.3rem;
  position: absolute;
  top: -1.3rem;
  right: 3.25rem;
}
.close_lotter{
  position: absolute;
  width: 20px!important;
  height: 20px;
  right: 116px;
  top: 0;
  border-radius: 50%;
  border: 0;
}
</style>
